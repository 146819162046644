<template>
  <div class="unit-plot-input">
    <fd-input
      class="mr-0"
      :value="value"
      @input="onInput"
      :validateFn="validateDuplicates"
      :validators="[validator.required]"
    >
      <template #append-icon>
        <button
          type="button"
          class="btn delete-plot"
          @click="$emit('removePlot')"
        >
          <i class="fas fa-trash"></i>
        </button>
      </template>
    </fd-input>
  </div>
</template>

<script>
import { required } from "@/components/GlobalComponents/FormComponents/Validator/rules";

export default {
  components: {},
  mixins: [],
  props: {
    value: {
      type: [Number, String]
    },
    usedPlots: {
      type: Array
    }
  },
  data: function () {
    return {
      validator: {
        required: required
      },
      errorMsg: ""
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    onInput(value) {
      this.$emit("input", value);
    },
    validateDuplicates() {
      let err = `${this.value} is used.`;
      if (this.usedPlots.includes(this.value)) {
        throw err;
      }
      return true;
    }
  }
};
</script>

<style lang="scss">
.unit-plot-input {
  .fd-input {
    input {
      height: 50px;
      font-size: 20px;
    }
  }
  .error {
    color: $color-danger;
  }
}
</style>
